var datalist = [
  //Utilities
  {
    title: 'Electricity',
    name: 'metadata-general-utilities-electricity',
    path: '/metadata/general/utilities/electricity',
    filePath: '/metadata/general/utilities/electricity',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Utilities',
        active: false,
        to: 'metdata-general-utilities',
      },
      {
        text: 'Electricity',
        active: false,
        to: 'metdata-general-utilities-electricity',
      },
    ]
  },
  {
    title: 'Water',
    name: 'metadata-general-utilities-water',
    path: '/metadata/general/utilities/water',
    filePath: '/metadata/general/utilities/water',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Utilities',
        active: false,
        to: 'metdata-general-utilities',
      },
      {
        text: 'Water',
        active: false,
        to: 'metdata-general-utilities-water',
      },
    ]
  },
  {
    title: 'Gas',
    name: 'metadata-general-utilities-gas',
    path: '/metadata/general/utilities/gas',
    filePath: '/metadata/general/utilities/gas',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Utilities',
        active: false,
        to: 'metdata-general-utilities',
      },
      {
        text: 'Gas',
        active: false,
        to: 'metdata-general-utilities-gas',
      },
    ]
  },

  {
    title: 'Cable TV',
    name: 'metadata-general-communication-cabletv',
    path: '/metadata/general/communication/cabletv',
    filePath: '/metadata/general/communication/cabletv',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Communication',
        active: false,
        to: 'metdata-general-communication',
      },
      {
        text: 'Cable TV',
        active: false,
        to: 'metdata-general-communication-cabletv',
      },
    ]
  },
  {
    title: 'Mobile',
    name: 'metadata-general-communication-mobile',
    path: '/metadata/general/communication/mobile',
    filePath: '/metadata/general/communication/mobile',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Communication',
        active: false,
        to: 'metdata-general-communication',
      },
      {
        text: 'mobile',
        active: false,
        to: 'metdata-general-communication-mobile',
      },

    ]
  },
  {
    title: 'Landline/Broadband',
    name: 'metadata-general-communication-landbroad',
    path: '/metadata/general/communication/landbroad',
    filePath: '/metadata/general/communication/landbroad',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Communication',
        active: false,
        to: 'metdata-general-communication',
      },
      {
        text: 'Landline/Broadband',
        active: false,
        to: 'metdata-general-communication-landbroad',
      },
    ]
  },
  ///Assistance///
  {
    title: 'Vaccination',
    name: 'metadata-general-assistance-vaccination',
    path: '/metadata/general/assistance/vaccination',
    filePath: '/metadata/general/assistance/vaccination',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Assistance',
        active: false,
        to: 'metdata-general-assistance',
      },
      {
        text: 'Vaccination',
        active: false,
        to: 'metdata-general-assistance-vaccination',
      },
    ]
  },
  {
    title: 'Vaccination Dosage',
    name: 'metadata-general-assistance-vaccinationdosage',
    path: '/metadata/general/assistance/vaccinationdosage',
    filePath: '/metadata/general/assistance/vaccinationdosage',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-general',
      },
      {
        text: 'Assistance',
        active: false,
        to: 'metdata-general-assistance',
      },
      {
        text: 'Vaccination Dosage',
        active: false,
        to: 'metdata-general-assistance-vaccinationdosage',
      },
    ]
  },
  ///Assets////
  {
    title: 'Household Services',
    name: 'metadata-physicalassets-householdservices',
    path: '/metadata/physicalassets/householdservices',
    filePath: '/metadata/physicalassets/householdservices',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Physical Assets',
        active: false,
        to: 'metdata-physicalassets',
      },
      {
        text: 'Householdservices',
        active: false,
        to: 'metdata-physicalassets-householdservices',
      },

    ]
  },
  {
    title: 'Househol Equipments',
    name: 'metadata-physicalassets-householdequipments',
    path: '/metadata/physicalassets/householdequipments',
    filePath: '/metadata/physicalassets/householdequipments',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Physical Assets',
        active: false,
        to: 'metdata-physicalassets',
      },
      {
        text: 'Householdservices',
        active: false,
        to: 'metdata-physicalassets-householdequipments',
      },

    ]
  },
  {
    title: 'Automobile',
    name: 'metadata-physicalassets-automobile',
    path: '/metadata/physicalassets/automobile',
    filePath: '/metadata/physicalassets/automobile',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Physical Assets',
        active: false,
        to: 'metdata-physicalassets',
      },
      {
        text: 'Automobile',
        active: false,
        to: 'metdata-physicalassets-automobile',
      },

    ]
  },
  {
    title: 'Important contacts',
    name: 'metadata-physicalassets-importantcontacts',
    path: '/metadata/physicalassets/importantcontacts',
    filePath: '/metadata/physicalassets/importantcontacts',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Physical Assets',
        active: false,
        to: 'metdata-physicalassets',
      },
      {
        text: 'Automobile',
        active: false,
        to: 'metdata-physicalassets-importantcontacts',
      },

    ]
  },

  //INSURANCE
  {
    title: 'Insurance',
    name: 'metadata-insurance-life',
    path: '/metadata/insurance/life',
    filePath: '/metadata/insurance/life',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Insurance',
        active: false,
        to: 'metdata-insurance',
      },
      {
        text: 'Life',
        active: false,
        to: 'metdata-insurance-life',
      },

    ]
  },
  {
    title: 'Insurance',
    name: 'metadata-insurance-general',
    path: '/metadata/insurance/general',
    filePath: '/metadata/insurance/general',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Insurance',
        active: false,
        to: 'metdata-insurance',
      },
      {
        text: 'General',
        active: false,
        to: 'metdata-insurance-general',
      },

    ]
  },
  {
    title: 'Mutual Fund',
    name: 'metadata-financialassets-mutualfund',
    path: '/metadata/financialassets/mutualfund',
    filePath: '/metadata/financialassets/mutualfund',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Finance',
        active: false,
        to: 'metdata-financialassets',
      },
      {
        text: 'Mutualfund',
        active: false,
        to: 'metdata-financialassets-mutualfund',
      },

    ]
  },
  // {
  //   title: 'Bank',
  //   name: 'metadata-financialassets-bank',
  //   path: '/metadata/financialassets/bank',
  //   filePath: '/metadata/financialassets/bank',
  //   breadcrumb: [{
  //       text: 'Metadata',
  //       active: false,
  //       to: 'metdata',
  //     },
  //     {
  //       text: 'Finance',
  //       active: false,
  //       to: 'metdata-financialassets',
  //     },
  //     {
  //       text: 'Bank',
  //       active: false,
  //       to: 'metdata-financialassets-bank',
  //     },

  //   ]
  // },
  {
    title: 'Broker',
    name: 'metadata-financialassets-broker',
    path: '/metadata/financialassets/broker',
    filePath: '/metadata/financialassets/broker',
    breadcrumb: [{
        text: 'Financial Assets',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Finance Assets',
        active: false,
        to: 'metdata-financialassets',
      },
      {
        text: 'Broker',
        active: false,
        to: 'metdata-financialassets-broker',
      },

    ]
  },
  {
    title: 'Loan',
    name: 'metadata-financialassets-loan',
    path: '/metadata/financialassets/loan',
    filePath: '/metadata/financialassets/loan',
    breadcrumb: [{
        text: 'Financial Assets',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Finance',
        active: false,
        to: 'metdata-financialassets',
      },
      {
        text: 'Loan',
        active: false,
        to: 'metdata-financialassets-loan',
      },

    ]
  },
  {
    title: 'Wallet',
    name: 'metadata-financialassets-wallet',
    path: '/metadata/financialassets/wallet',
    filePath: '/metadata/financialassets/wallet',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Financial Assets',
        active: false,
        to: 'metdata-financialassets',
      },
      {
        text: 'Wllet',
        active: false,
        to: 'metdata-financialassets-wallet',
      },

    ]
  },
  {
    title: 'Paylater Wallets',
    name: 'metadata-financialassets-paylaterwallets',
    path: '/metadata/financialassets/paylaterwallets',
    filePath: '/metadata/financialassets/paylaterwallets',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Finance',
        active: false,
        to: 'metdata-financialassets',
      },
      {
        text: 'Paylater Wallets',
        active: false,
        to: 'metdata-financialassets-paylaterwallets',
      },

    ]
  },
  ////////TRANSATION////

  {
    title: 'Income',
    name: 'metadata-transaction-income',
    path: '/metadata/transaction/income',
    filePath: '/metadata/transaction/income',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Transaction',
        active: false,
        to: 'metdata-transaction',
      },
      {
        text: 'Income',
        active: false,
        to: 'metdata-transaction-income',
      },

    ]
  },
  {
    title: 'Expense',
    name: 'metadata-transaction-expense',
    path: '/metadata/transaction/expense',
    filePath: '/metadata/transaction/expense',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Transaction',
        active: false,
        to: 'metdata-transaction',
      },
      {
        text: 'Expense',
        active: false,
        to: 'metdata-transaction-expense',
      },

    ]
  },
  {
    title: 'Expense Sub',
    name: 'metadata-transaction-expensesub',
    path: '/metadata/transaction/expensesub',
    filePath: '/metadata/transaction/expensesub',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Transaction',
        active: false,
        to: 'metdata-transaction',
      },
      {
        text: 'Expense Sub',
        active: false,
        to: 'metdata-transaction-expensesub',
      },

    ]
  },
  ////Subscriptions/////

  {
    title: 'Subscriptions',
    name: 'metadata-subscriptions-subscriptions',
    path: '/metadata/assets/subscriptions',
    filePath: '/metadata/subscriptions/subscriptions',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Subscriptions',
        active: false,
        to: 'metdata-subscriptions',
      },
      {
        text: 'subscriptions',
        active: false,
        to: 'metdata-subscriptionssub-subscriptions',
      },

    ]
  },
  {
    title: 'Subscriptions Sub',
    name: 'metadata-subscriptionssub-subscriptionssub',
    path: '/metadata/assets/subscriptionssub',
    filePath: '/metadata/subscriptions/subscriptionssub',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'subscriptionssub',
        active: false,
        to: 'metdata-subscriptionssub',
      },
      {
        text: 'subscriptionssub',
        active: false,
        to: 'metdata-subscriptionssub-subscriptionssub',
      },

    ]
  },
  /////////////Make/////////
  {
    title: ' Make',
    name: 'metadata-assets-make',
    path: '/metadata/assets/make',
    filePath: '/metadata/assets/make',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Assets',
        active: false,
        to: 'metdata-assets',
      },
      {
        text: 'Make',
        active: false,
        to: 'metdata-assets-assets',
      },

    ]
  },
  // ARTS AND COLLECTS
  {
    title: 'Arts and Colleectible',
    name: 'metadata-physicalassets-artsandcollectible',
    path: '/metadata/physicalassets/artsandcollectible',
    filePath: '/metadata/physicalassets/artsandcollectible',
    breadcrumb: [{
        text: 'Metadata',
        active: false,
        to: 'metdata',
      },
      {
        text: 'Assets',
        active: false,
        to: 'metdata-physicalassets',
      },
      {
        text: 'Arts and Collectible',
        active: false,
        to: 'metdata-physicalassets-artsandcollectible',
      },

    ]
  },
];


let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'Metadata',
      breadcrumb: datalist[i].breadcrumb
    },

  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'Metadata',
      breadcrumb: [{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'Metadata',
      breadcrumb: [{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'Metadata',
      breadcrumb: [{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);


}
export default data
