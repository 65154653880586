import Vue from 'vue'
// 192.168 .194 .171
// axios
import axios from 'axios'
import {
  initialAbility
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import router from "@/router";
let userData = JSON.parse(localStorage.getItem('userData'))
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://adminfrp.smartfrp.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
// locaL baseURL: 'htttp://frpadmin2.com/api/',
// <!- Network URL- htttps://adminfrp.smartfrp.com -->

axiosIns.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('userData')
    router.push({
      name: 'auth-login'
    })
  }
  if (503 === error.response.status) {
    router.push({
      name: 'auth-otp'
    })
  }
  return Promise.reject(error);
});
Vue.prototype.$http = axiosIns

export default axiosIns
