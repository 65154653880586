var datalist = [
    {
      title: 'Pricing',
      name: 'settings-application-pricing',
      path: '/settings/application/pricing',
      filePath: '/settings/application/pricing',
    },
    {
      title: 'Storage',
      name: 'settings-application-storage',
      path: '/settings/application/storage',
      filePath: '/settings/application/storage',
    },
  {
    title: 'Country',
    name: 'settings-application-country',
    path: '/settings/application/country',
    filePath: '/settings/application/country',
  },
  {
    title: 'State',
    name: 'settings-application-state',
    path: '/settings/application/state',
    filePath: '/settings/application/state',
  },
  {
    title: 'CMS',
    name: 'settings-application-cms',
    path: '/settings/application/cms',
    filePath: '/settings/application/cms',
  },
  {
    title: 'Relations',
    name: 'settings-application-relations',
    path: '/settings/application/relations',
    filePath: '/settings/application/relations',
  },
];


let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'Settings',
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);


}
export default data
