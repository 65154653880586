export default [

    {
      path: '/importexport',
      name: 'import-export',
      component: () => import('@/views/metadata/importexport/list.vue'),
      meta: {
        pageTitle: 'Import Export',
        breadcrumb: [
          {
            text: 'Metadata',
          },
        ],
      },
    },

]